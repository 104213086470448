import { elb } from '@elbwalker/walker.js';

import type {
  AuthEventCustomData,
  GoToCartCustomData,
  GoToCheckoutCustomData,
  GoToPaymentCustomData,
  InputErrorCustomData,
  ManageProductCustomData,
  UnavailableProductRemovalEventData,
  PurchaseAttemptCustomData,
  PurchaseEventCustomData,
  PurchaseFailureEventCustomData,
  SkipShopEventCustomData,
  VoucherAppliedCustomData,
} from 'shared/services/tracker/types';
import { EVENTS } from 'shared/services/walker/constants';

import { parsePrice } from '../shared';

import { createProductNestedEntity } from './services';

/**
 * GO_TO_PAYMENT handler
 * @param customData
 */
export function goToPaymentHandler(customData: GoToPaymentCustomData): void {
  const { currency, order } = customData;
  const { id, products, total } = order;
  const nested = createProductNestedEntity(products);

  const totalQuantity = nested.reduce(
    (acc, item) => acc + item.data.quantity,
    0,
  );

  elb(
    EVENTS.SHIPPING_COMPLETE,
    {
      currency,
      id,
      value: total,
      quantity: totalQuantity,
    },
    'custom',
    {},
    nested,
  );
}

/**
 * PURCHASE handler
 * @param customData
 */
export function purchaseCheckoutSuccessHandler(
  customData: PurchaseEventCustomData,
): void {
  const { paymentMethod, order, couponCode, currencyCode, email, phoneNumber } =
    customData;
  const nested = createProductNestedEntity(order.products);
  const totalQuantity = nested.reduce(
    (acc, item) => acc + item.data.quantity,
    0,
  );

  elb(
    EVENTS.PAYMENT_SUCCESS,
    {
      coupon: couponCode || '',
      currency: currencyCode,
      payment_type: paymentMethod,
      id: order.id,
      value: order.total,
      quantity: totalQuantity,
      email,
      phoneNumber,
    },
    'custom',
    {},
    nested,
  );
}

/**
 * PURCHASE_FAILURE handler
 * @param customData
 */
export function purchaseFailureHandler(
  customData: PurchaseFailureEventCustomData,
): void {
  const {
    paymentMethod,
    errorCode,
    errorMessage,
    order,
    couponCode,
    currencyCode,
  } = customData;

  const failureMessage = errorCode
    ? `Failed to process ${paymentMethod} - Failure reason: ${errorCode} - ${errorMessage}`
    : `Failed to process ${paymentMethod} with unknown error or 3DS validation failure`;

  const nested = createProductNestedEntity(order.products);

  const totalQuantity = nested.reduce(
    (acc, item) => acc + item.data.quantity,
    0,
  );

  elb(
    EVENTS.PAYMENT_ERROR,
    {
      coupon: couponCode || '',
      currency: currencyCode,
      payment_type: paymentMethod,
      id: order.id,
      value: order.total,
      option: failureMessage, // failure reason
      quantity: totalQuantity,
    },
    'custom',
    {},
    nested,
  );
}

/**
 * SKIP_SHOP handler
 * @param customData
 */
export function skipShopHandler(customData: SkipShopEventCustomData): void {
  const { label, userId, orderId } = customData;

  elb(EVENTS.SKIP_SHOP, { label, userId, orderId }, 'custom', {});
}

/**
 * GO_TO_CHECKOUT handler
 * @param customData
 */
export function goToCheckoutHandler(customData: GoToCheckoutCustomData): void {
  const { products, total, currency } = customData;
  const nested = createProductNestedEntity(products);
  elb(
    EVENTS.BEGIN_CHECKOUT,
    {
      currency,
      value: total,
    },
    'custom',
    {},
    nested,
  );
}

/**
 * PURCHASE_ATTEMPT handler
 * @param _customData
 */
export function purchaseAttemptHandler(
  customData: PurchaseAttemptCustomData,
): void {
  const { paymentMethod, order, couponCode, currencyCode } = customData;
  const nested = createProductNestedEntity(order.products);
  const totalQuantity = nested.reduce(
    (acc, item) => acc + item.data.quantity,
    0,
  );

  elb(
    EVENTS.PAYMENT_COMPLETE,
    {
      coupon: couponCode || '',
      currency: currencyCode,
      payment_type: paymentMethod,
      id: order.id,
      value: order.total,
      quantity: totalQuantity,
    },
    'custom',
    {},
    nested,
  );
}

/**
 * ADD_PRODUCT handler
 * @param customData
 */
export function addProductHandler(customData: ManageProductCustomData): void {
  const { product, currency } = customData;
  const price = parsePrice(product.price);
  // @ts-expect-error walker.js types does not support items array
  elb(EVENTS.ADD_TO_CART, {
    currency,
    value: Number(price) * product.quantity,
    items: [
      {
        item_id: product.code,
        item_name: product.trackingId || product.code,
        price,
        quantity: product.quantity.toString(),
      },
    ],
  });
}

/**
 * GO_TO_CART handler
 * @param _customData
 */
export function goToCart(customData: GoToCartCustomData): void {
  const { products, total, currency } = customData;
  const nested = createProductNestedEntity(products);
  elb(
    EVENTS.GO_TO_CART,
    {
      currency,
      value: total,
    },
    'custom',
    {},
    nested,
  );
}

/**
 * SIGNUP handler
 */
export function signupHandler(customData: AuthEventCustomData): void {
  const { email, intent, referralMerchantCode, referrer } = customData;

  elb(
    EVENTS.SIGNUP,
    {
      email,
      ...(intent ? { intent } : {}),
      ...(referrer
        ? {
            business_feature: 'referrals',
            referral_code: referrer,
            referral_merchant_code: referralMerchantCode,
          }
        : {}),
    },
    'custom',
    {},
  );
}

/**
 * LOGIN handler
 */
export function loginHandler(): void {
  elb(EVENTS.LOGIN);
}

/**
 * VOUCHER_APPLIED handler
 * @param _customData
 */
export function voucherAppliedHandler(
  customData: VoucherAppliedCustomData,
): void {
  const { couponCode } = customData;
  elb(EVENTS.VOUCHER_APPLIED, {
    coupon: couponCode || '',
  });
}

/**
 * INPUT_ERROR handler
 * @param _customData
 */
export function inputErrorHandler(customData: InputErrorCustomData): void {
  const { errorField } = customData;
  elb(EVENTS.INPUT_ERROR, {
    option: errorField,
  });
}

/**
 * UNAVAILABLE_PRODUCT_REMOVED handler
 * @param customData
 */
export function unavailableProductRemovalHandler(
  customData: UnavailableProductRemovalEventData,
): void {
  const { product, orderId, country } = customData;
  elb(EVENTS.UNAVAILABLE_PRODUCT_REMOVED, {
    product_sku: product.code,
    order_id: orderId,
    country,
  });
}

export function paymentOnFileUpdateModalOpenHandler(): void {
  elb(EVENTS.PAYMENT_ON_FILE_UPDATE_MODAL_OPENED);
}

export function paymentOnFileUpdatedHandler({
  status,
}: {
  status: 'success' | 'failure';
}): void {
  elb(EVENTS.PAYMENT_ON_FILE_UPDATED, { status });
}

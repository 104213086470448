import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import type { Maybe } from 'types/util';

export enum EVENTS {
  PAGEVIEW = 'PAGEVIEW',

  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE',
  CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY',
  CHANGE_PRODUCT_QUANTITY_FAILURE = 'CHANGE_PRODUCT_QUANTITY_FAILURE',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE',
  UNAVAILABLE_PRODUCT_REMOVED = 'UNAVAILABLE_PRODUCT_REMOVED',

  GO_TO_CART = 'GO_TO_CART',
  GO_TO_CHECKOUT = 'GO_TO_CHECKOUT',
  GO_TO_PAYMENT = 'GO_TO_PAYMENT',

  PROCEED_AS_REGISTERED_USER = 'PROCEED_AS_REGISTERED_USER',

  SIGNUP = 'SIGNUP',
  SIGNUP_FAILURE = 'SIGNUP_FAILURE',

  LOGIN = 'LOGIN',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  PURCHASE_ATTEMPT = 'PURCHASE_ATTEMPT',
  PURCHASE = 'PURCHASE',
  PURCHASE_GA4 = 'PURCHASE_GA4',
  PURCHASE_FAILURE = 'PURCHASE_FAILURE',

  GO_TO_ACTIVATION = 'GO_TO_ACTIVATION',

  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE',

  CREATE_CHECKOUT = 'CREATE_CHECKOUT',
  CREATE_CHECKOUT_FAILURE = 'CREATE_CHECKOUT_FAILURE',

  ORDER_PLACED = 'ORDER_PLACED',
  ORDER_PLACEMENT_FAILURE = 'ORDER_PLACEMENT_FAILURE',

  ATTACH_BOLETO_INFORMATION = 'ATTACH_BOLETO_INFORMATION',
  ATTACH_BOLETO_INFORMATION_FAILED = 'ATTACH_BOLETO_INFORMATION_FAILED',

  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_CREATED_FAILURE = 'ORDER_CREATED_FAILURE',

  PAYMENT_CALLBACK_INVOKED = 'PAYMENT_CALLBACK_INVOKED',

  OPTIMIZELY_INIT = 'OPTIMIZELY_INIT',
  OPTIMIZELY_INIT_FAILURE = 'OPTIMIZELY_INIT_FAILURE',

  'EVENT_404' = '404',
  'EVENT_500' = '500',

  MORE_INFO = 'MORE_INFO',

  SKIP_SHOP = 'SKIP_SHOP',

  SEARCH_ADDRESS_FAILURE = 'SEARCH_ADDRESS_FAILURE',
  ADDRESS_SUGGESTION_SELECTED = 'ADDRESS_SUGGESTION_SELECTED',

  ORDER_STATUS_FETCHED = 'ORDER_STATUS_FETCHED',
  ORDER_STATUS_FETCH_FAILED = 'ORDER_STATUS_FETCH_FAILED',

  ECOM_CLIENT_INIT = 'ECOM_CLIENT_INIT',
  ECOM_CLIENT_INIT_FAILURE = 'ECOM_CLIENT_INIT_FAILURE',

  INPUT_ERROR = 'INPUT_ERROR',

  VOUCHER_APPLIED = 'VOUCHER_APPLIED',

  GO_TO_WEBSITE = 'GO_TO_WEBSITE',

  BA_LEARN_MORE_OPEN = 'BA_LEARN_MORE_OPEN',
  BA_LEARN_MORE_CLOSE = 'BA_LEARN_MORE_CLOSE',
  BA_SELECTED = 'BA_SELECTED',
  BA_UNSELECTED = 'BA_UNSELECTED',
  BA_CONDITIONS_MODAL_OPEN = 'BA_CONDITIONS_MODAL_OPEN',
  BA_CONDITIONS_MODAL_CLOSE = 'BA_CONDITIONS_MODAL_CLOSE',

  VISITED_PRODUCT_DETAILS_PAGE = 'VISITED_PRODUCT_DETAILS_PAGE',
  VISITED_PRODUCT_SELECTION_PAGE = 'VISITED_PRODUCT_SELECTION_PAGE',

  PAYMENT_ON_FILE_UPDATE_MODAL_OPEN = 'PAYMENT_ON_FILE_UPDATE_MODAL_OPEN',
  PAYMENT_ON_FILE_UPDATED = 'PAYMENT_ON_FILE_UPDATED',
}

export type Product = {
  code: string;
  trackingId: string;
  price?: string;
  quantity?: number;
};

export type Order = {
  id: string;
  products: Product[];
  total: number;
};

export interface OptionalDefaultInfo {
  merchantCode?: string;
  country?: string;
}

export interface PageviewHandlerCustomData extends OptionalDefaultInfo {
  [custom: string]: unknown;
}

export interface ManageProductCustomData extends OptionalDefaultInfo {
  product: Product;
  currency?: string;
}

export interface UnavailableProductRemovalEventData
  extends OptionalDefaultInfo {
  orderId: string;
  product: Omit<Product, 'trackingId'>;
}

export interface AuthEventCustomData extends OptionalDefaultInfo {
  merchantCode: string;
  referralMerchantCode?: string;
  referrer?: string;
  email?: string;
  userId?: string;
  orderId: Maybe<string>;
  intent?: string;
}

export interface PurchaseEventCustomData extends OptionalDefaultInfo {
  order: Order;
  paymentMethod: PaymentMethod;
  currencyCode?: string;
  merchantCountryCode?: string;
  userId?: string;
  email?: string;
  phoneNumber?: string;
  couponCode?: string;
}

export interface PurchaseFailureEventCustomData extends OptionalDefaultInfo {
  paymentMethod: string;
  errorMessage?: string;
  errorCode?: string;
  order: Order;
  couponCode?: string;
  currencyCode?: string;
  merchantCountryCode?: string;
}

export interface GoToCheckoutCustomData extends OptionalDefaultInfo {
  channel?: string;
  orderId?: string;
  currency?: string;
  total?: number;
  products?: Product[];
}
export interface GoToCartCustomData extends OptionalDefaultInfo {
  channel?: string;
  orderId?: string;
  currency?: string;
  total?: number;
  products?: Product[];
}

export interface PurchaseAttemptCustomData {
  order: Order;
  paymentMethod: string;
  currencyCode?: string;
  merchantCountryCode?: string;
  couponCode?: string;
}

export interface ProceedAsRegisteredUserCustomData {
  merchantCode?: string;
  orderId: Maybe<string>;
}

export interface MoreInfoCustomData extends OptionalDefaultInfo {
  from?: 'button' | 'image';
  productName?: string;
  userId?: string;
  orderId?: string;
}

export interface InputErrorCustomData extends OptionalDefaultInfo {
  errorField: string;
}

export interface VoucherAppliedCustomData extends OptionalDefaultInfo {
  couponCode: string;
}

export interface SkipShopEventCustomData extends OptionalDefaultInfo {
  label?: string;
  userId?: string;
  orderId?: string;
  trackingId?: string;
  context?: 'product_card';
}

export interface GoToWebsiteCustomData extends OptionalDefaultInfo {
  link: string;
}

export interface BusinessAccountSelectedCustomData extends OptionalDefaultInfo {
  label: string;
}

export interface BusinessAccountUnselectedCustomData
  extends OptionalDefaultInfo {
  label: string;
}

export interface GoToPaymentCustomData extends OptionalDefaultInfo {
  currency: string;
  order: Order;
  merchant_country_code: string;
}

export interface GoToActivationCustomData {
  country: string;
  orderId?: string;
  merchantCode?: string;
}

export interface AddressSuggestionSelectedCustomData {
  orderId?: string;
  merchantCode?: string;
}
